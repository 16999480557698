.top-bar { padding-bottom: 0 !important; }

.top-bar-left { width:100% !important;  }
.menu-centered { display:inline-block; margin: 0 auto !important; @include breakpoint(small only) { display: block !important; width:100%; } }
.menu-centered li { display:inline-block !important; font-size: rem-calc(20); font-family: $palatino !important; @include breakpoint(small only) { display: block !important; width:100%; } }
.menu-centered li.show-for-large { 
    @include breakpoint(small) { display:none!important; }; 
    @include breakpoint(large) { display:inline-block!important; }
}
.menu-centered li:after {
      content: '';
      display: block;
      margin: auto;
      height: 3px;
      width: 0;
      background: rgba(0, 0, 0, 0);
      transition: width .5s ease,background-color .5s ease;
      margin-top: 3px;
  }
.menu-centered li.active:after, .menu-centered li:hover:after {
      width: 100%;
    background: rgba(255, 255, 255, 0.3);
  }
.menu-centered li a, .menu .active > a { color: $white !important; background: transparent !important; text-decoration:none !important; }
.menu .active > a, .menu-centered li a:hover { text-decoration:none !important; }
.menu > li > a { padding: 0.7rem 1rem; @include breakpoint(medium only) { padding: 0.5rem 0.5rem; } }

.tabs { background: transparent; border: none; border-bottom: 4px solid $blue; @extend .martop60; } 
.tabs-content{ background: transparent; border: none; } 
.tabs-title > a { background: $cream; color: $blue!important; font-family: $caveat; font-size: rem-calc(35); text-decoration: none!important; border-radius: 5px 5px 0px 0px; margin-left: 20px; }
.tabs-title:first-of-type > a { margin-left: 0px; }
.tabs-title > a:focus, .tabs-title > a[aria-selected='true'] { background: $blue; color: #fff!important; }
.tabs-title > a:hover { background: $blue; color: #fff!important; }
.tabs-panel { display:none !important; opacity: 0; transition: opacity 0.2s ease-in-out;}
.tabs-panel.is-active { display:block !important; opacity: 1; }

.thumbnail { margin-bottom: 30px !important; }
.reveal { top: 50px !important; } 

.button { 
  font-family: $caveat;
	border-radius: 4px; 
	font-size: rem-calc(30) !important; 
	font-weight: bold; 
	text-transform: none; 
	margin-bottom: 0; 
	text-decoration:none !important;
  background-color: $white; 
  color: $red;
  border-radius: 3px;

	@include breakpoint(small only) { font-size: rem-calc(16); padding: rem-calc(7 10); }
}

.is-dropdown-submenu { z-index: 1000; }

.red.button { background-color: $white; color: $red !important; }  
.red.button:hover { background-color: scale-color($white, $lightness: 15%); color: $red; }
.blue.button { background-color: $white; color: $blue !important; } 
.blue.button:hover { background-color: scale-color($white, $lightness: -15%); color: $blue; }

.redbutton .button { background-color: $white; color: $red !important; } 
.redbutton .button:hover { background-color: scale-color($white, $lightness: -15%); color: $red; }
.bluebutton .button { background-color: $white; color: $blue !important; } 
.bluebutton .button:hover { background-color: scale-color($white, $lightness: -15%); color: $blue; }

blockquote, blockquote p { line-height: 1.17 !important; color: $blue !important; font-size: rem-calc(36); Font-family: $caveat; @include breakpoint(small only) {font-size: rem-calc(26)};}
blockquote { border-left: 5px solid $cream !important;  padding: 0.5625rem 1.25rem 0.5625rem 1.1875rem; margin: 30px 40px 30px !important; }  

div#sector-carousel {
    margin-top: 50px;
}
hr {
    max-width: 75rem;
    height: 0;
    border-right: 0;
    border-top: 0;
    border-bottom: 3px solid $cream;
    border-left: 0;
    margin: 1.25rem auto;
    clear: both;
    margin-top: 2rem;
}
a.button.kindle {
    background: #5D111D url(/assets/img/amazon-bg.png) center center no-repeat;
    color: transparent !important;
}

a.button.audible {
    background: #5D111D url(/assets/img/audible-bg.png) center center no-repeat;
    color: transparent !important;
}
a.button.soldout {
    background-color: #999 !important;
    color: #fff !important;
    font-family: Palatino Linotype, Palatino, Book Antiqua, serif !important;
    text-transform: uppercase;
    font-size: 20px !important;
    padding: 20px 20px;
}

.no-js {
  @include breakpoint(small only) {
    .top-bar {
      display: none;
    }
  }

  @include breakpoint(medium) {
    .title-bar {
      display: none;
    }
  }
}