header {
    background-color: $red;
    padding: 30px 15px;
    @include breakpoint(medium down) { padding: 0px 15px; }


	p.telno { 
		font-size: rem-calc(32); 
		font-weight: bold; 

		span { 
			display: block;
			color: $dark-gray;
			font-size: rem-calc(20); 
		} 
	}
}

.title-bar { @include breakpoint(medium down) { margin-left: -15px; margin-right: -15px; text-align: center; } }

img.flower { @include breakpoint(medium down) { max-height: 60px; } }

img.suee { @include breakpoint(medium down) { max-height: 60px; } @include breakpoint(small only) { margin-top: 15px; }}
img.logo { @include breakpoint(small only) { max-width: 70%; } }
 
section.banner-area {
    width: 100%;
    margin: 0px auto;
    padding: 150px 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    h1 { color: $white;}
}

section.banner-area.homepage {
    background: $white;
	padding: 0px 0;
    position: relative; 
    text-align: center;

    .header-image { position: absolute; bottom: 80px; text-align: center; left: 0; right: 0; margin-left: auto; margin-right: auto; @include breakpoint(medium down) { bottom: rem-calc(40); } }
    .header-image h1 { margin-bottom: 30px; font-family: $palatino; font-size: rem-calc(70); @include breakpoint(small only) { font-size: rem-calc(35); } @include breakpoint(medium only) { font-size: rem-calc(40); } }
    .header-image a { display: inline-block; }
 
}

