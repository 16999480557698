@mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
/*-----  Padding & Margins  -----*/
h1, h2, h3, h4 { font-family: $palatino !important; }
h1.blue, h2.blue, h3.blue, h4.blue { color: $blue; }
@each $size in ( 10,20, 30, 40, 50, 60 ) {
	.pad#{$size} {
	padding-top: rem-calc($size); 
	padding-bottom: rem-calc($size); 
	}
	.padall#{$size} {
	padding: rem-calc($size); 
	@include breakpoint(medium down) { padding: rem-calc($size / 2); }
	}
	.padbot#{$size} {
	padding-bottom: rem-calc($size); 
	}
	.padtop#{$size} {
	padding-top: rem-calc($size); 
	}
	.marbot#{$size} {
	margin-bottom: rem-calc($size); 
	}
	.martop#{$size} {
	margin-top: rem-calc($size); 
	}
}
.owl-controls {
		height: 30px;
		@extend .martop20;
		@extend .marbot20;
	}
	.owl-dots {
		height: 30px;
		text-align:center;
	}
	.owl-dot {
		height: 20px;
		width: 20px;
		background-color:$blue;
		border-radius: 50%;
		display:inline-block;
		margin:5px
	}
	.owl-dot.active {
		background-color: $red;
	}
#main-carousel .owl-controls { display:none; }

/*-----  Utility classes  -----*/
.vcparent {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
	position:relative
}
img { max-width:100%; height:auto!important;}
img.img-left { float: left; padding-right:40px; padding-bottom:40px; }
img.img-right { float: right; padding-left:40px; padding-bottom:40px; }
.stack-margin { @include breakpoint(small only) { margin-top: rem-calc(20)}; }
.bgimage { background-repeat: no-repeat; background-size: cover; }
.bgimagecntr { background-repeat: no-repeat; background-size: cover; background-position: 50%; }
.bgimgblck { background-repeat: no-repeat; background-position: 5% 50%; @include breakpoint(medium down) { background-position: 50% 10%; } } 
.p220 { min-height: 200px; }
.posx { background-position: 50%; }
.post { background-position: 50% 0%; }
.vcenter { @include vertical-align; }
.maxheight { height: 100%; }
.centerall { @include absolute-center; }
.thumbnail { border: 0px !important;
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.2) !important;
    cursor: pointer; }
 

	@include breakpoint(small only) { .smpad { margin-top: rem-calc(30); } }
p.intro {
    border: 4px solid $light-gray;
    border-width: 4px 0;
    padding: 20px 0;
    font-size: 24px;
    margin: 30px auto;

     @include breakpoint(small only) {font-size: rem-calc(18)};
}

/*-----  backgrounds  -----*/
.bgwhite {
	background-color: $white; 

	p:last-of-type { margin-bottom: 0; }
}

.bgcream {
	background-color: $cream; 
	h1, h2 { color: $blue; }
	p { color: #000; }
}

.bgred {
	background-color: $red; 
	p, h2 { color: $white; }
}

.bgblue {
	background-color: $blue; 
	p, h2 { color: $white; }
}

.whitetriangle:before { @include css-triangle(30px, $white, down); margin:0 auto; margin-bottom: rem-calc(30); border-width:27px 36.5px 0 36.5px;}
.ytriangle:before { @include css-triangle(30px, $yellow, down); margin:0 auto; margin-bottom: rem-calc(30); border-width:27px 36.5px 0 36.5px;}

section.breadcrumb { @extend .pad40;
	a { color: $red !important; text-decoration: none !important; } 
	span.breadcrumb-separator { display: inline-block; padding: 0px 10px; }
 }  
.main-content h1 span {
    font-size: 40px;
    display: block;
    margin-bottom: 20px;
}

aside.sidebar {
	p.newstitle, p.chatter {
    margin-top: 20px;
    font-size: rem-calc(22);
    padding-right: 0px;
    margin-left: 0%; 
	}
	p.chatter a{
    margin-top: 20px;
    color: $red !important;
	}
	.journal {
		h2 { color: $red; margin-bottom:1rem;  }
		a.button { background-color: $cream; }
	}
	.menu a { font-family: $palatino; font-size: rem-calc(23); color: $white !important; text-decoration: none !important; }
	.menu li:after {
	    content: '';
	    display: block;
	    margin: auto;
	    height: 3px;
	    width: 0;
	    background: rgba(0, 0, 0, 0);
	    transition: width .5s ease,background-color .5s ease;
	    margin-top: 3px;
	}
	.menu li.active:after, .menu li:hover:after {
    	width: 100%;
		background: rgba(255, 255, 255, 0.3);
	}


	.journal-sidebar h3 {
    font-size: rem-calc(40);
    color: $red;
    margin-bottom: rem-calc(20);
}
.journal-sidebar h4 {
    font-size: rem-calc(30);
    color: $red;
    margin-bottom: rem-calc(15);
}
.journal-sidebar p {
    font-size: rem-calc(22);
    color: $red;
}
.journal-sidebar .button {
    margin-bottom:20px;
}
.journal-sidebar .button {
    background-color:$red;
}
.journal-sidebar ul {
    text-align:left !important;
    padding-left: rem-calc(70);
    color: $red;
}
	& > div {
    	margin-top: rem-calc(40);
	}
	& > div:first-of-type {
    	margin-top: 0px;
	}
}

.close-button {
    color: #900 !important;
    /* background-color: #fff; */
    width: 40px;
    padding-top: 3px;
    font-size: 3rem;
}

p.image-text {
    background: $yellow; 
    max-width: 308px;
    padding: 6px 10px;
    text-align: center;
    margin: 0 auto;
}

/*----- BLOG ----- */
a.button.loadmore {
    margin: 50px auto 20px auto;
    display: block;
    max-width: rem-calc(300);
}

.panel {
    background-color: #F8F5ED;
    padding: rem-calc(10 15);
    margin: rem-calc(30 0 50 0);
}

.columns.comments {
    font-family: $caveat;
    color: $red;
    font-size: rem-calc(30);
    line-height: 1;
}
.columns.links {
    padding-top: 3px;
}
a.blogwrapper {
    text-decoration: none!important;
}
a.blogwrapper p { color: #333; }
/*-----  HTML  -----*/
	a { color: $red !important; text-decoration: underline !important; } 

	#mapcanvas {
	    position: relative;
	    height: 460px !important;
	    min-height: 460px !important;
	    max-height: 460px !important;
	    padding-bottom: 1%;
	    margin-bottom: 1rem;
	    overflow: hidden;
	}

	ul.tabs { margin-top:50px; }
	#contact-tabs { border-bottom:4px solid $light-gray; border-top: none; }
	#contact-tabs .tabs-title a { border: none; font-size: rem-calc(24); text-decoration: none !important; }
	#contact-tabs .tabs-title a:hover { color: #333; text-decoration: underline; }

main { 


    
	ul {
	    list-style: none;
	    margin-left: 80px;
	    margin-bottom: 40px;

	    @include breakpoint(small only) { margin-left: 40px; };
	}
	ul li {
	    background: url(/assets/img/bullets.png) 0px 9px no-repeat;
	    padding-left:20px;
	    margin-top:30px;
	}

	h3, h2 {
	    margin-top: 1em;
	    margin-bottom:0.3em;
	}
	h2:first-of-type { margin-top: 0; }
	p { margin-bottom:1.3em; font-size: rem-calc(17); }
} 

.main-content {
	h1,h2,h3 { color: $blue; margin-bottom: 1rem; }
	h1 span { font-size: 40px; display: block; margin-bottom: 20px; }
	h2 { margin-top: 2rem; }
	a.button, button.button { background-color: $cream; }
}
.errorholder {margin-top:30px;}
/*-----  HTML specific  -----*/

p:last-of-type { margin-bottom: 0; }
	p.big { font-size: rem-calc(24); line-height: 1.3; @include breakpoint(small only) {font-size: rem-calc(18)}; }

	.main-box {
    text-align: center;
}

a.close-reveal-modal {
    text-decoration: none!important;
    font-size: 40px;
    position: absolute;
    top: -20px;
    right: 0px;
}
#subscribeform button.large {
    background-color: $cream;
}

h3#modalTitle {color: $red;}
a.bookpic {
    text-align: center;
    text-decoration: none!important;
    margin-top: 40px;
    display: block;
    color: $blue !important;
}

a.bookpic img {
    margin: 0 auto 10px auto;
    display: block;
}