.threeblox {
	h4 {  padding: 13px 0px; margin-bottom: 0; text-align: center; font-weight:normal; color:$blue; font-family: $caveat !important; @include breakpoint(medium up) { font-size: rem-calc(40); };@include breakpoint(small) { font-size: rem-calc(28); }; }
	img { width: 100%; }  
	p { backgorund: $white; padding:20px; text-align: center; font-size: rem-calc(17); transition: all .4s ease-in; } 
	p:first-of-type { padding: 0; margin: 0; }
	div.hover { cursor: pointer; }
	div.hover a { display: block; color: $body-font-color; text-decoration:none !important; }
	div.hover:hover p.hover { 
		background: #e5e5e5;
		background: -webkit-linear-gradient(#e5e5e5 0%, #ffffff 100%);
		background: -o-linear-gradient(#e5e5e5 0%, #ffffff 100%);
		background: linear-gradient(#e5e5e5 0%, #ffffff 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e5e5e5', endColorstr='#ffffff',GradientType=0 );
	}
}

#journal-carousel{
	.item a { width:100%; display:inline-block; }
	.item img {
	    max-width: 100%!important;
	    width: auto;
	    margin: 0 auto;
	}
}

span.date {
    display: block;
    font-size: rem-calc(17);
}

p.newstitle, p.chatter {
	font-size: rem-calc(28);
	text-align: left;
	padding-right: 30px; 
	margin-left: 33%;
}
p.newstitle a { font-size: rem-calc(28); color: #fff !important; text-decoration: none !important; }

p.chatter { margin-left: 50%; } 

p.newstitle.vcenter, p.chatter.vcenter { @include breakpoint(medium down) { top: 0%; transform: translateY(0); margin-left: 30px; margin-top: 150px; }; }    

.minheight { min-height:207px; margin-bottom: 30px; @include breakpoint(medium down) { margin:15px auto; min-height:1px; width:100%; height: auto!important; }; }

.homepage h2 { @include breakpoint(medium) { font-size: rem-calc(60); }; @include breakpoint(small) { font-size: 30px; }; }
