.parallax { h2 {color: $white; }; p {color: $white;} }


footer{
	@extend .padtop50;
	background: $red;
	border-top: 10px solid $white; 

	.bgcream { margin-top: rem-calc(50); 
		padding: 6px 0px 20px 0px;
		a { display: inline-block; padding: 14px 0px 0px 14px; 

			img { @include breakpoint(small) { max-height: 32px; }; @include breakpoint(medium) { max-height: none; }; }

		}
		a:first-of-type { display: inline-block; padding: 14px 0px 0px 0px; }
		a:nth-last-child(3) { @include breakpoint(medium) { padding-left: 14px; } @include breakpoint(small) { padding-left: 0px; }; }
	}
	
	h3 { color: $white; text-align: center; font-family: arial, sans-serif; }
	p { color: $white; 
		font-size: rem-calc(18);
		&.small { font-size: rem-calc(15); color: $medium-gray; }
	}
	& > div:last-of-type { background-color: $white;
		p { font-size: rem-calc(14); margin: 15px 0; color: $body-font-color; } 
		a { color: $red !important; text-decoration: underline; };
		a:hover { color: $red; }
	} 

	img.footer-logo { @include breakpoint(medium down) { margin-bottom: 25px; }; }

}

.parallax { background-image: url(/assets/img/whats-in-a-name.jpg); background-attachment: fixed; background-position: 50%; background-repeat: no-repeat; height: auto; position: relative; background-size:cover; }